<template>
  <portal selector="#login-portal">
    <v-app>
      <div class="login-overlay">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
              <v-card class="elevation-12">
                <v-toolbar color="secondary" dark flat>
                  <v-toolbar-title>Signup an account</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <ValidationObserver v-slot="{ invalid, validated, passes }">
                  <v-card-text>
                    <v-form>
                      <TextFieldWithValidation
                        rules="required|min:4"
                        label="name"
                        v-model="name"
                        prepend-icon="mdi-account"
                        type="text"
                      />
                      <TextFieldWithValidation
                        rules="required|email"
                        label="E-mail"
                        v-model="email"
                        prepend-icon="mdi-email"
                        type="email"
                      />
                      <TextFieldWithValidation
                        rules="required|min:6"
                        v-model="password"
                        label="Password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        prepend-icon="mdi-lock"
                        @click:append="showPassword = !showPassword"
                      />
                    </v-form>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="(validated && invalid) || pending"
                        :loading="pending"
                        color="secondary"
                        min-width="200"
                        @click="passes(signupSubmit)"
                        >Signup</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <span class="caption">Have account?</span>
                      <v-btn x-small text color="secondary" to="/login"
                        >log in</v-btn
                      >
                    </div>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-app>
  </portal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import TextFieldWithValidation from '../components/BaseTextFieldWIthValidation';

import { mapState } from 'vuex';

export default {
  name: 'app-signup',
  components: {
    ValidationObserver,
    TextFieldWithValidation,
  },
  data: () => ({
    name: '',
    email: '',
    password: '',
    showPassword: false,
  }),
  computed: mapState({
    user: state => state.auth.user,
    pending: state => state.auth.fetchingUserData,
  }),
  methods: {
    signupSubmit() {
      this.$store.dispatch('signupUserWithEmail', {
        name: this.name,
        email: this.email,
        password: this.password,
      });
    },
  },
  mounted: function() {
    document.documentElement.style.overflow = 'hidden';
  },
  beforeDestroy: function() {
    document.documentElement.style.overflow = 'auto';
  },
};
</script>
