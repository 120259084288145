<template>
  <portal selector="#login-portal">
    <v-app>
      <div class="login-overlay">
        <v-container>
          <v-row align="center" justify="center" v-if="!timeoutEnabled">
            <v-col cols="12" sm="8" md="6">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Enter your email</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <ValidationObserver v-slot="{ invalid, validated, passes }">
                  <v-card-text>
                    <v-form>
                      <TextFieldWithValidation
                        rules="required|email"
                        label="E-mail"
                        v-model="email"
                        prepend-icon="mdi-account"
                        type="email"
                      />
                    </v-form>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="(validated && invalid) || pending"
                        :loading="pending"
                        color="primary"
                        min-width="150"
                        @click="passes(resetSubmit)"
                        >Reset password</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <span class="caption">back to login</span>
                      <v-btn x-small text color="primary" to="/login"
                        >login</v-btn
                      >
                    </div>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-else>
            <v-col cols="12" sm="8" md="6">
              <v-card class="elevation-12 sent-email-wrapper">
                <v-card-text>
                  Email wtih reset password instructions has been sent to
                  {{ emailFromStorage }}. <v-spacer></v-spacer>
                  <div class="d-flex footer">
                    <v-spacer></v-spacer>
                    <span class="caption">back to login</span>
                    <v-btn x-small text color="primary" to="/login"
                      >login</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-app>
  </portal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import TextFieldWithValidation from '../components/BaseTextFieldWIthValidation';
import storage, {
  PASSWORD_RESET_KEY,
  PASSWORD_RESET_TIMEOUT,
} from '@/store/localStore';
import { mapState } from 'vuex';

export default {
  name: 'app-login',
  components: {
    ValidationObserver,
    TextFieldWithValidation,
  },
  data: () => ({
    email: '',
    emailSent: false,
    timeoutEnabled: false,
    emailFromStorage: '',
  }),
  computed: mapState({
    user: state => state.auth.user,
    pending: state => state.auth.fetchingUserData,
  }),
  methods: {
    async resetSubmit() {
      await this.$store.dispatch('sendResetEmail', {
        email: this.email,
      });
      this.timeoutEnabled = true;
      this.emailFromStorage = this.email;
    },
  },
  mounted: async function() {
    document.documentElement.style.overflow = 'hidden';
    const fromStorage = await storage.generalStorage.getItem(
      PASSWORD_RESET_KEY
    );
    if (
      fromStorage &&
      Date.now() - PASSWORD_RESET_TIMEOUT <= fromStorage.date
    ) {
      this.timeoutEnabled = true;
      this.emailFromStorage = fromStorage.email;
    }
  },
  beforeDestroy: function() {
    document.documentElement.style.overflow = 'auto';
  },
};
</script>
<style lang="sass" scoped>
.sent-email-wrapper
  height: 120px
  +flex-ctr()
  .footer
    margin-top: 16px
</style>
